import React, { useEffect, useState } from 'react'
import Layout from '../components/layout'
import MerchantSignUpButton from '../components/merchantSignUpButton'
import { Link } from 'gatsby'

// const futureDate = new Date(2022, 1, 1)
// const today = new Date()

// const getDateDiff = (date1, date2) => {
//   const diff = new Date(date2.getTime() - date1.getTime())
//   return {
//     month: diff.getUTCMonth(),
//     day: diff.getUTCDate() - 1,
//     hour: diff.getUTCHours(),
//     minute: diff.getUTCMinutes(),
//     second: diff.getUTCSeconds()
//   }
// }

export default function Index () {
  // const [diff, setDiff] = useState({})
  // const [isLiveCalgary, setIsLiveCalgary] = useState(false)

  // useEffect(() => {
  //   if (!isLiveCalgary && futureDate > today) {
  //     const timer = setInterval(() => {
  //       setDiff(getDateDiff(new Date(), futureDate))
  //     }, 1000)

  //     return () => clearInterval(timer)
  //   } else {
  //     setIsLiveCalgary(true)
  //   }
  // })

  return (
    <Layout>
      <section className='mb-0 home-hero'>
        <div className='row align-center'>
          <div className='col f3'>
            <div className="inner">
              <h1>Local delivery <br className='d-only' />when you need it</h1>
              <p className='lede mb'>Now, same-day, whenever, Trexity delivers. With a flexible, scalable, and cost-effective driver fleet, the new standard of door-to-door service is ready for you.</p>
              <MerchantSignUpButton style={{ marginBottom: 15 }} />
              <p className='mice'>Currently available in: Ottawa, Toronto, Winnipeg, and Calgary, Canada.</p>
            </div>
          </div>
          <div className='col f2 d-only'>
            <div className="inner half">
              <img src="/img/home-hero.jpg" alt="Local delivery graphic" />
            </div>
          </div>
        </div>
      </section>

      <section className="banner m-mb-0">
        <div className='row align-center justify-center'>
          <div className="col text-center">
            <div className='inner half'>
              <p>La Bottega Nicastro, a family-run Italian grocer, talks about their experience offering home delivery with Trexity.</p>
              <Link to='/merchant-profiles/la-bottega-nicastro' className='cta-link'>View profile<i className="material-icons">keyboard_arrow_right</i></Link>
            </div>
          </div>
        </div>
      </section>

      <section className='well blue mb-0'>
        <div className='row align-center justify-center'>
          <div className="col w75 text-left d-text-center">
            <div className='inner'>
              <p className='lede'>“Amazing technology run by a tremendous team of hard-working problem solvers! If you want a simple and well-thought out delivery solution, think Trexity.”</p>
              <strong>- Pelican Seafood Market &amp; Grill</strong>
            </div>
          </div>
        </div>
      </section>

      <section className='well'>
        <div className="inner half merchant-logos">
          <a href="https://www.labottega.ca/" target="_blank" rel="noreferrer"><img src="/img/merchants/bottega.jpg" alt="La Bottega Nicastro logo" /></a>
          <a href="https://makerhouse.com/" target="_blank" rel="noreferrer"><img src="/img/merchants/makerhouse.jpg" alt="Maker House logo" /></a>
          <a href="https://naturalfoodpantry.ca/" target="_blank" rel="noreferrer"><img src="/img/merchants/natural-food-pantry.jpg" alt="Natural Food Pantry logo" /></a>
          <a href="https://nrml.ca/" target="_blank" rel="noreferrer"><img src="/img/merchants/nrml.jpg" alt="NRML logo" /></a>
          <a href="https://overflowbeer.com/" target="_blank" rel="noreferrer"><img src="/img/merchants/overflow.jpg" alt="Overflow Beer logo" /></a><a href="https://www.pelicanseafood.ca/" target="_blank" rel="noreferrer"><img src="/img/merchants/pelican.jpg" alt="Pelican Seafood logo" style={{ maxHeight: 25 }} /></a>
          <a href="https://barfromafar.com/" target="_blank" rel="noreferrer"><img src="/img/merchants/bar-from-afar.jpg" alt="Bar from afar logo" /></a>
          <a href="https://jacobsons.ca/" target="_blank" rel="noreferrer"><img src="/img/merchants/jacobsons.jpg" alt="Jacobsons logo" style={{ maxHeight: 15 }} /></a>
        </div>
      </section>

      <section>
        <div className='row equal align-center'>
          <div className='col'>
            <div className="inner">
              <h2>No surprises—full visibility, full control</h2>
              <p>Track each delivery in real-time and get direct notifications at each stage. Schedule and manage deliveries to work with your business and workflow.</p>
              <Link to='/merchants/' className='cta-link'>Learn more <i className="material-icons">keyboard_arrow_right</i></Link>
            </div>
          </div>
          <div className='col'>
            <div className="inner image">
              <img src="/img/home-interface.jpg" alt="Sample of interface" />
            </div>
          </div>
        </div>
      </section>

      <section>
        <div className='row align-center justify-center'>
          <div className='col w75'>
            <div className="inner text-left d-text-center">
              <h2>How Trexity works</h2>
              <p>Add your deliveries into our Merchant Portal and request a driver or schedule a time. We’ll come pick it up from your storefront and deliver it to your customer—simple.</p>
              <p className='mice mb'>Deliveries are automatically added through e-commerce integrations.</p>
              <ol className='equal-steps'>
                <li className='step'>
                  <div>
                    <div className='tile'><i className='material-icons'>add</i></div>
                    <span className='context'>Add deliveries</span>
                  </div>
                </li>
                <li className='step'>
                  <div>
                    <div className='tile'><i className='material-icons'>person</i></div>
                    <span className='context'>Request driver</span>
                  </div>
                </li>
                <li className='step'>
                  <div>
                    <div className='tile'><i className='material-icons'>store</i></div>
                    <span className='context'>Pick up</span>
                  </div>
                </li>
                <li className='step'>
                  <div>
                    <div className='tile'><i className='material-icons'>drive_eta</i></div>
                    <span className='context'>Delivery</span>
                  </div>
                </li>
                <li className='step'>
                  <div>
                    <div className='tile end green'><i className='material-icons'>check_circle</i></div>
                    <span className='context'>Confirmation</span>
                  </div>
                </li>
              </ol>
            </div>
          </div>
        </div>
      </section>

      <section>
        <div className='row equal reverse align-center'>
          <div className='col'>
            <div className="inner image">
              <img src="/img/home-shop.jpg" alt="Shopping bag" />
            </div>
          </div>
          <div className='col'>
            <div className="inner">
              <h2>Upgrade your shop, no coding required</h2>
              <p>Use the Trexity Merchant Portal or add Trexity to your Shopify store, log in, and choose your delivery options. We match your orders as they’re fulfilled to make sure they get delivered.</p>
              <Link to='/merchants/' className='cta-link'>Learn more <i className="material-icons">keyboard_arrow_right</i></Link>
            </div>
          </div>
        </div>
      </section>

      <section className='well callout'>
        <div className='row equal align-center'>
          <div className='col'>
            <div className="inner">
              <h2>Honest work means complete transparency</h2>
              <p>We show all costs upfront in seconds and provide monthly reports with statistics on all transactions. Pricing is based on time and distance, not a percentage of your goods sold.</p>
              <Link to='/pricing/' className='cta-link'>See pricing <i className="material-icons">keyboard_arrow_right</i></Link>
            </div>
          </div>
          <div className='col'>
            <div className="inner image">
              <img src="/img/home-transparent.jpg" alt="Interface mockup" />
            </div>
          </div>
        </div>
      </section>

      <section className='well callout'>
        <div className='row equal reverse align-center'>
          <div className='col'>
            <div className="inner image">
              <img src="/img/home-talk.jpg" alt="Woman talking with someone over the computer" />
            </div>
          </div>
          <div className='col'>
            <div className="inner">
              <h2>Talk with a human</h2>
              <p>Book a virtual meeting with us and we’ll happily answer any questions you may have surrounding local delivery and how our platform can work with your workflow.</p>
              <a href="https://calendly.com/trexitysales/30min" target='_blank' rel='noopener noreferrer' className='cta-link'>Book a meeting <i className="material-icons">keyboard_arrow_right</i></a>
            </div>
          </div>
        </div>
      </section>

      <section>
        <div className='row justify-center'>
          <div className='col w75 text-center'>
            <div className="inner">
              <h2>Spend your time growing your business</h2>
              <p style={{ marginBottom: 30 }}>Get started today—no monthly fees, time contracts, or pricey additions. Focus on what you want to do, let us handle the delivery.</p>
              <MerchantSignUpButton />
            </div>
          </div>
        </div>
      </section>
    </Layout>
  )
}
